
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Advance search batch report',
  components: {
    Datatable,
  },

  data() {
    return {
      pdfHeader: [] as any,
      tranches: [] as any,
      TranceData: [] as any,
      start_date: '',
      IsAssessedLabel: '',
      BatchStatusLabel: '',
      IsEmployedLabel: '',
      IsCertifiedLabel: '',
      end_date: '',
      TranceLabel: [] as any,
      TpartnerLabel: [] as any,
      TinstituteLabel: [] as any,
      TbatchesLabel: [] as any,
      TcourseTypeLabel: [] as any,
      TcoursesLabel: [] as any,
      selectedDistricts: [] as any,
      selectedTrance: [],
      batchStatus: [
        {
          id: 1,
          label: 'running',
        },
        {
          id: 2,
          label: 'completed',
        },
      ] as any,
      assessedStatus: [
        {
          id: 1,
          label: 'Yes',
        },
        {
          id: 2,
          label: 'No',
        },
      ],
      certifiedStatus: [
        {
          id: 1,
          label: 'Yes',
        },
        {
          id: 2,
          label: 'No',
        },
      ],
      employedStatus: [
        {
          id: 1,
          label: 'Yes',
        },
        {
          id: 2,
          label: 'No',
        },
      ],
      updatedArray: [] as any,
      TpartnerData: [] as any,
      TinstituteData: [] as any,
      IsAssessedData: '' as any,
      BatchStatusData: '' as any,
      AssessementDueDays: '' as any,
      CertificationDueDays: '',
      IsCertifiedData: '',
      IsEmployedData: '',
      TbatchData: [] as any,
      TcoursesData: [] as any,
      courseType: [] as any,
      courseList: [] as any,
      TcourseTypeData: [],
      tableData: [] as any,
      tableHeader: [
        {
          name: 'Training Partner',
          key: 'association',
          sortable: true,
          selected: true,
        },
        {
          name: 'Institute Name',
          key: 'institute',
          sortable: true,
          selected: true,
        },
        {
          name: 'Course Name',
          key: 'course_name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Entity Contract',
          key: 'entity_contract',
          sortable: true,
          selected: true,
        },
        {
          name: 'Batch Number',
          key: 'batch_no',
          sortable: true,
          selected: true,
        },

        {
          name: 'Training Location',
          key: 'training_location',
          sortable: true,
          selected: true,
        },
        {
          name: 'Participant Capacity',
          key: 'capcaity',
          sortable: true,
          selected: true,
        },
        {
          name: 'Start Date',
          key: 'start_date',
          sortable: true,
          selected: true,
        },
        {
          name: 'End Date',
          key: 'end_date',
          sortable: true,
          selected: true,
        },
        {
          name: 'Total Training Hour',
          key: 'training_hour',
          sortable: true,
          selected: true,
        },
        {
          name: 'Total Training Days',
          key: 'training_days',
          sortable: true,
          selected: true,
        },
        {
          name: 'Is Eligible for Enrollment',
          key: 'is_eligible',
          sortable: true,
          selected: true,
        },
        {
          name: 'Ext Enroll Date',
          key: 'ext_enroll_date',
          sortable: true,
          selected: true,
        },

        {
          name: 'Total Term',
          key: 'total_term',
          sortable: true,
          selected: true,
        },
        // {
        //   name: "Active Status",
        //   key: "active_status",
        //   sortable: true,
        //   selected: true,
        // },
        {
          name: 'Lead Trainer',
          key: 'lead_trainer',
          sortable: true,
          selected: true,
        },
        {
          name: 'Associate Trainer',
          key: 'associate_trainer',
          sortable: true,
          selected: true,
        },
      ] as any,

      st: [] as any,
      instituteList: [] as any,
      batchList: [] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      entityInfos: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,
    };
  },
  async created() {
    await this.getTranche();
    await this.associationList();
    await this.getcoursetypes();
  },
  methods: {
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
           duration: 0,
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('courses', this.TcoursesData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch_status', this.BatchStatusData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      formData.set('assessment_status', this.IsAssessedData);
      formData.set('certification_status', this.IsCertifiedData);
      formData.set('employment_status', this.IsEmployedData);
      formData.set('assessment_due_days', this.AssessementDueDays);
      formData.set('certification_due_days', this.CertificationDueDays);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/advance_batch_excel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'advance_search_batch.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async printPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('courses', this.TcoursesData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch_status', this.BatchStatusData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      formData.set('assessment_status', this.IsAssessedData);
      formData.set('certification_status', this.IsCertifiedData);
      formData.set('employment_status', this.IsEmployedData);
      formData.set('assessment_due_days', this.AssessementDueDays);
      formData.set('certification_due_days', this.CertificationDueDays);

      let data = `${this.VUE_APP_API_URL}/api/report/advance_batch_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
           duration: 0,
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('courses', this.TcoursesData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch_status', this.BatchStatusData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      formData.set('assessment_status', this.IsAssessedData);
      formData.set('certification_status', this.IsCertifiedData);
      formData.set('employment_status', this.IsEmployedData);
      formData.set('assessment_due_days', this.AssessementDueDays);
      formData.set('certification_due_days', this.CertificationDueDays);

      // console.log(JSON.stringify(this.st));

      await ApiService.post('report/advance_batch_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    trancheLabelChange(data) {
      this.TranceLabel = [];
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.tranches.length; j++) {
          if (data[i] == this.tranches[j].id) {
            this.TranceLabel.push(this.tranches[j].label);
          }
        }
      }
    },
    trainingPartnerLabelChange(data) {
      this.getInstitute();
      this.TpartnerLabel = [];
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.entityInfos.length; j++) {
          if (data[i] == this.entityInfos[j].id) {
            this.TpartnerLabel.push(this.entityInfos[j].entity_short_name);
          }
        }
      }
    },
    courseTypeLabelChange(data) {
      this.getCourses();
      this.TcourseTypeLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseType.length; j++) {
          if (data[i] == this.courseType[j].id) {
            this.TcourseTypeLabel.push(this.courseType[j].type_name);
          }
        }
      }
    },
    trainingInstituteLabelChange(data) {
      this.getCourses();
      this.TinstituteLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.instituteList.length; j++) {
          if (data[i] == this.instituteList[j].id) {
            this.TinstituteLabel.push(this.instituteList[j].short_name);
          }
        }
      }
    },
    courseLabelChange(data) {
      this.TcoursesLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseList.length; j++) {
          if (data[i] == this.courseList[j].id) {
            this.TcoursesLabel.push(this.courseList[j].course_name);
          }
        }
      }
    },
    batchStatusLabelChange(data) {
      for (let i = 0; i < this.batchStatus.length; i++) {
        if (data == this.batchStatus[i].id) {
          this.BatchStatusLabel = this.batchStatus[i].label;
        }
      }
    },
    assessedStatusLabelChange(data) {
      for (let i = 0; i < this.assessedStatus.length; i++) {
        if (data == this.assessedStatus[i].id) {
          this.IsAssessedLabel = this.assessedStatus[i].label;
        }
      }
    },
    employedStatusLabelChange(data) {
      for (let i = 0; i < this.employedStatus.length; i++) {
        if (data == this.employedStatus[i].id) {
          this.IsEmployedLabel = this.employedStatus[i].label;
        }
      }
    },
    certifiedStatusLabelChange(data) {
      for (let i = 0; i < this.certifiedStatus.length; i++) {
        if (data == this.certifiedStatus[i].id) {
          this.IsCertifiedLabel = this.certifiedStatus[i].label;
        }
      }
    },
    // courseTypeLabelChange(data) {
    //   this.getCourses();
    // },
    async getCourses() {
      ApiService.get(
        'course/list?courseType=' +
        this.TcourseTypeData +
        '&entity=' +
        this.TpartnerData +
        '&tranche=' +
        this.TranceData +
        '&institute_info_id=' +
        this.TinstituteData
      )
        .then((response) => {
          this.courseList = response.data.data;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    resetData() {
      this.tableData = [] as any;
      // label clear
      this.TranceLabel = [] as any;
      this.instituteList = [] as any;
      this.courseList = [] as any;
      this.TpartnerLabel = [] as any;
      this.TinstituteLabel = [] as any;
      this.TcourseTypeLabel = [] as any;
      this.TcoursesLabel = [] as any;
      this.start_date = '' as any;
      this.end_date = '' as any;
      this.IsAssessedData = '' as any;
      this.BatchStatusData = '' as any;
      this.IsCertifiedData = '' as any;
      this.IsEmployedData = '' as any;
      this.AssessementDueDays = '' as any;
      this.CertificationDueDays = '' as any;
      // data clear
      (this.TranceData = [] as any),
        (this.TpartnerData = [] as any),
        (this.TinstituteData = [] as any),
        (this.TcourseTypeData = [] as any),
        (this.TcoursesData = [] as any),
        (this.BatchStatusData = [] as any),
        (this.TranceData = [] as any),
        (this.TranceData = [] as any),
        (this.st = [] as any);
      this.showall = false;
      this.showTableData = false;
      this.componentTableKey += 1;
    },

    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      // console.log(entity_id);
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getcoursetypes() {
      ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.courseType = response.data.data;
          console.log(this.courseType);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getInstitute() {
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'institute/list?entity_id=' +
        this.TpartnerData +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          console.log(response);
          this.instituteList = response.data.data;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async getData() {
      this.load = true;
      await ApiService.get(
        'batch/advanced-search?tranche=' +
        this.TranceData +
        '&entity=' +
        this.TpartnerData +
        '&institute=' +
        this.TinstituteData +
        '&courses=' +
        this.TcoursesData +
        '&start_date=' +
        this.start_date +
        '&end_date=' +
        this.end_date +
        '&batch_status=' +
        this.BatchStatusData +
        '&assessment_status=' +
        this.IsAssessedData +
        '&certification_status=' +
        this.IsCertifiedData +
        '&employment_status=' +
        this.IsEmployedData +
        '&assessment_due_days=' +
        this.AssessementDueDays +
        '&certification_due_days=' +
        this.CertificationDueDays
      )
        .then((response) => {
          this.headerSelect = true;
          this.selectedTableHeader();
          this.tableData = response.data.data;
          console.log(this.tableData);
          this.componentTableKey += 1;
          this.load = false;
        })
        .then((response) => {
          console.log(response);
          this.load = false;
        });
    },

    selectedTableHeader() {
      this.st = Array();
      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
      // this.tableHeader.map((item) => {
      //   if (item.selected) {
      //     this.st.push(
      //       JSON.parse(JSON.stringify({ name: item.name, key: item.key }))
      //     );
      //   } else {
      //   }
      // });
    },
  },

  // beforeMount() {
  //   this.selectedTableHeader();
  // },
  computed: {},
  setup() { },
});
